import { computed, Injectable, Signal } from '@angular/core'
import { EntitySignalQuery } from '@awork/core/state/signal-store/entitySignalQuery'
import { ProjectStore } from '@awork/features/project/state/project.store'
import { map, Observable, take } from 'rxjs'
import { LinkedChat, Project } from '@awork/features/project/models/project.model'
import { ProjectStatusType } from '@awork/features/project/models/project-status.model'
import { UserQuery } from '@awork/features/user/state/user.query'
import { getSortByGroup } from '@awork/features/project/functions/project-sorting'
import { BrowserService } from '@awork/_shared/services/browser-service/browser.service'
import { SettingsQuery } from '@awork/framework/state/settings.query'
import { GroupByOptions } from '@awork/_shared/models/group-by-options.model'
import { areDatesOverlapping, DateRange } from '@awork/_shared/functions/date-operations'
import { Order } from '@awork/core/state/signal-store/types'

export enum ProjectColumn {
  Teams = 'teams',
  Tags = 'tags',
  DueOn = 'dueOn',
  TrackedHours = 'trackedHours',
  Time = 'time',
  Budget = 'budget',
  Tasks = 'tasks',
  Progress = 'progress',
  Lead = 'lead',
  Members = 'members',
  Status = 'status'
}

export interface ClosedFilters {
  searchQuery: string
  statusType: ProjectStatusType | ProjectStatusType[]
}

export type QuickFilter = 'all' | 'my' | 'progress' | 'closed'

interface SelectAllProjectOptions {
  limit?: number
  closedFilters?: Partial<ClosedFilters>
  projectStatusOrder?: boolean
  companyId?: string
  memberId?: string
  teamIds?: string[]
  projectGrouping?: GroupByOptions
  dateRange?: DateRange
  includeExternal?: boolean
}

@Injectable({ providedIn: 'root' })
export class ProjectQuery extends EntitySignalQuery<Project> {
  constructor(
    protected store: ProjectStore,
    private userQuery: UserQuery,
    private settingsQuery: SettingsQuery,
    private browserService: BrowserService
  ) {
    super(store)
  }

  /**
   * Selects the assigned active projects
   * @param {number} limit
   * @param {string} searchQuery
   * @param {boolean} active
   * @param {boolean} includeExternal
   */
  selectAssignedActiveProjects(
    limit?: number,
    searchQuery?: string,
    active = true,
    includeExternal = false
  ): Observable<Project[]> {
    return this.selectAll({
      filterBy: project =>
        this.assignedActiveProjectsFilter(project, searchQuery, active) &&
        this.filterExternalProjects(project, includeExternal),
      sortBy: 'name',
      sortByOrder: Order.ASC,
      limitTo: limit
    }).pipe(map(projects => this.mapEntities(projects)))
  }

  /**
   * Gets the assigned active projects
   * @param {number} limit
   * @param {string} searchQuery
   * @param {boolean} active
   * @param {boolean} includeExternal
   * @returns {Signal<Project[]>}
   */
  queryAssignedActiveProjects(
    limit?: number,
    searchQuery?: string,
    active = true,
    includeExternal = false
  ): Signal<Project[]> {
    return computed(() => {
      const projects = this.queryAll({
        filterBy: project =>
          this.assignedActiveProjectsFilter(project, searchQuery, active) &&
          this.filterExternalProjects(project, includeExternal),
        sortBy: 'name',
        sortByOrder: Order.ASC,
        limitTo: limit
      })

      return this.mapEntities(projects())
    })
  }

  /**
   * Gets the external projects
   * @param {boolean} isActive
   * @returns {Signal<Project[]>}
   */
  queryExternalProjects(isActive = true): Signal<Project[]> {
    return computed(() => {
      const projects = this.queryAll({
        filterBy: project =>
          !!project.isExternal && (!isActive || this.progressFilter(project) || this.notStartedFilter(project)),
        sortBy: 'name',
        sortByOrder: Order.ASC
      })
      return this.mapEntities(projects())
    })
  }

  /**
   * Gets the count of assigned projects
   * @param {string} searchQuery
   * @param {boolean} active
   * @param {boolean} includeExternal
   * @returns {number}
   */
  assignedActiveProjectsCount(searchQuery?: string, active = true, includeExternal = false): number {
    return this.getCount(
      project =>
        this.assignedActiveProjectsFilter(project, searchQuery, active) &&
        this.filterExternalProjects(project, includeExternal)
    )
  }

  /**
   * Filters out assigned and active projects
   * @param {Project} project
   * @param {string} searchQuery
   * @param {boolean} active
   * @returns
   */
  assignedActiveProjectsFilter(project: Project, searchQuery?: string, active = true): boolean {
    return (
      this.assignedFilter(project) &&
      this.searchFilter(project, searchQuery) &&
      (!active || this.progressFilter(project))
    )
  }

  /**
   * Gets the not-done (not-started, progress) projects
   * @param {boolean} includeExternal
   * @returns {Signal<Project[]>}
   */
  queryNotDoneProjects(includeExternal = false): Signal<Project[]> {
    return computed(() => {
      const projects = this.queryAll({
        filterBy: project => {
          const type = project.projectStatus?.type
          return (
            (type === 'not-started' || type === 'progress') && this.filterExternalProjects(project, includeExternal)
          )
        },
        sortBy: 'name',
        sortByOrder: Order.ASC
      })

      return this.mapEntities(projects())
    })
  }

  /**
   * Gets the visible columns in list
   * When is not widescreen the array is used to determine which actions are visible
   * @returns {Signal<ProjectColumn[]>}
   */
  queryColumns(): Signal<ProjectColumn[]> {
    const defaultLargeList = [
      ProjectColumn.Tags,
      ProjectColumn.DueOn,
      ProjectColumn.Progress,
      ProjectColumn.Tasks,
      ProjectColumn.Lead,
      ProjectColumn.Status
    ]

    return computed(() => {
      const listColumns = this.settingsQuery.queryListColumns('project')

      return (listColumns()?.largeList as ProjectColumn[]) || defaultLargeList
    })
  }

  /**
   * Gets the projects by status type
   * @param {ProjectStatusType} statusType
   * @param {number} limit
   * @param {string} searchQuery
   * @param {string} companyId
   * @param {string} memberId
   * @param {GroupByOptions} projectGrouping
   * @param {boolean} currentUserAssigned
   * @param {DateRange} dateRange
   * @param {boolean} includeExternal
   * @returns {Signal<Project[]>}
   */
  queryByStatusType(
    statusType?: ProjectStatusType | ProjectStatusType[],
    limit?: number,
    searchQuery?: string,
    companyId?: string,
    memberId?: string,
    projectGrouping?: GroupByOptions,
    currentUserAssigned?: boolean,
    dateRange?: DateRange,
    includeExternal = false
  ): Signal<Project[]> {
    const projects = statusType
      ? this.queryAll({
          filterBy: project => {
            return (
              this.statusTypeFilter(project, statusType) &&
              this.searchFilter(project, searchQuery) &&
              this.companyFilter(project, companyId) &&
              this.memberFilter(project, memberId) &&
              this.currentUserFilter(project, currentUserAssigned) &&
              this.dateRangeFilter(project, dateRange) &&
              this.filterExternalProjects(project, includeExternal)
            )
          },
          sortBy: (projectA, projectB) => this.getSort(projectA, projectB, undefined, projectGrouping),
          limitTo: limit
        })
      : this.queryAllProjects({
          limit,
          closedFilters: { searchQuery },
          projectStatusOrder: true,
          companyId,
          memberId,
          projectGrouping,
          dateRange
        })

    return computed(() => this.mapEntities(projects()))
  }

  /**
   * Selects project by id
   * @param {string} id
   */
  selectProject(id: string): Observable<Project> {
    return this.selectEntity(id).pipe(
      map(project => {
        if (project) {
          project.linkedChats = this.filterUniqueLinkedChats(project)
          return this.mapEntity(project)
        }

        return null
      })
    )
  }

  /**
   * Filters linked chats to avoid duplicated providers
   * @param {Project} project
   * @returns {LinkedChat[]}
   */
  private filterUniqueLinkedChats(project: Project): LinkedChat[] {
    return (
      project.linkedChats &&
      project.linkedChats.reduce((uniqueArray, linkedChat) => {
        const providerExist = !!uniqueArray.find(uniqueLinkedChat => uniqueLinkedChat.provider === linkedChat.provider)

        // If the provider is not in the array, add it
        if (!providerExist) {
          uniqueArray.push(linkedChat)
        }
        return uniqueArray
      }, [])
    )
  }

  /**
   * Gets the project's linkedChats by provider
   * @param projectId
   * @param provider
   */
  getLinkedChatsByProvider(projectId: string, provider): LinkedChat {
    const project = this.getProject(projectId)
    return project && project.linkedChats && project.linkedChats.find(linkedChat => linkedChat.provider === provider)
  }

  /**
   * Gets project by id
   * @param {string} id
   */
  getProject(id: string): Project {
    const project = this.getEntity(id)

    return this.mapEntity(project)
  }

  /**
   * Gets the project by id
   * @param {string} id
   * @param options - The options to use
   * @param {'default' | 'resourceVersion'} options.equalityCheck - The equality check to use
   * * @returns {Signal<Project>}
   */
  queryProject(id: string, options?: { equalityCheck?: 'default' | 'resourceVersion' }): Signal<Project> {
    return computed(
      () => {
        const project = this.queryEntity(id)
        return this.mapEntity(project())
      },
      {
        equal: (a, b) => this.equalityCheck(a, b, options)
      }
    )
  }

  /**
   * Checks if 2 projects are equal by equality check option
   * @param {Project} a
   * @param {Project} b
   * @param options - The options to use
   * @param {'default' | 'resourceVersion'} options.equalityCheck - The equality check to use
   * @private
   * @return boolean
   */
  private equalityCheck(a: Project, b: Project, options?: { equalityCheck?: 'default' | 'resourceVersion' }): boolean {
    if (!options?.equalityCheck || options.equalityCheck === 'default') {
      return a === b
    }

    return a?.resourceVersion === b?.resourceVersion
  }

  /**
   * Gets the active project
   * @returns {Signal<Project>}
   */
  queryActiveProject(): Signal<Project> {
    return computed(() => {
      const project = this.queryActive()
      return this.mapEntity(project())
    })
  }

  /**
   * Gets the active project's id
   * @returns {Signal<string>}
   */
  queryActiveProjectId(): Signal<string> {
    return computed(() => {
      const project = this.queryActiveProject()
      return project()?.id
    })
  }

  /**
   * Selects projects by ids
   * @param {string[]} ids
   * @param {boolean} sync
   */
  selectProjects(ids: string[], sync = false): Observable<Project[]> {
    const selectQuery = this.selectMany(ids).pipe(map(projects => projects.sort(this.sortProjects)))

    return sync
      ? selectQuery.pipe(
          take(1),
          map(project => this.mapEntities(project))
        )
      : selectQuery.pipe(map(projects => this.mapEntities(projects)))
  }

  /**
   * Gets projects by ids
   * @param {string[]} ids
   * @returns {Signal<Project[]>}
   */
  queryProjects(ids: string[]): Signal<Project[]> {
    return computed(() => {
      const projects = this.queryMany(ids)
      return this.mapEntities(projects()).sort(this.sortProjects)
    })
  }

  /**
   * Gets projects by ids
   * @param {string[]} ids
   */
  getProjects(ids: string[]): Project[] {
    const projects = this.getAll({
      sortBy: 'name',
      sortByOrder: Order.ASC,
      filterBy: project => ids.includes(project.id)
    })

    return this.mapEntities(projects)
  }

  /**
   * Selects all projects
   * @param {SelectAllProjectOptions} options
   * @returns {Observable<Project[]>}
   */
  selectAllProjects(
    options: SelectAllProjectOptions = { projectStatusOrder: true, includeExternal: false }
  ): Observable<Project[]> {
    const {
      limit,
      closedFilters,
      projectStatusOrder,
      companyId,
      memberId,
      teamIds,
      projectGrouping,
      dateRange,
      includeExternal
    } = options

    return this.selectAll({
      sortBy: (projectA, projectB) => this.getSort(projectA, projectB, projectStatusOrder, projectGrouping),
      filterBy: project =>
        this.searchFilter(project, closedFilters?.searchQuery) &&
        this.statusTypeFilter(project, closedFilters?.statusType) &&
        this.companyFilter(project, companyId) &&
        this.memberFilter(project, memberId) &&
        this.teamFilter(project, teamIds) &&
        this.filterExternalProjects(project, includeExternal) &&
        this.dateRangeFilter(project, dateRange),
      limitTo: limit
    }).pipe(map(projects => this.mapEntities(projects)))
  }

  /**
   * Gets all projects
   * @param {SelectAllProjectOptions} options
   * @returns {Signal<Project[]>}
   */
  queryAllProjects(
    options: SelectAllProjectOptions = { projectStatusOrder: true, includeExternal: false }
  ): Signal<Project[]> {
    const {
      limit,
      closedFilters,
      projectStatusOrder,
      companyId,
      memberId,
      teamIds,
      projectGrouping,
      dateRange,
      includeExternal
    } = options

    return computed(() => {
      const projects = this.queryAll({
        sortBy: (projectA, projectB) => this.getSort(projectA, projectB, projectStatusOrder, projectGrouping),
        filterBy: project =>
          this.searchFilter(project, closedFilters?.searchQuery) &&
          this.statusTypeFilter(project, closedFilters?.statusType) &&
          this.companyFilter(project, companyId) &&
          this.memberFilter(project, memberId) &&
          this.teamFilter(project, teamIds) &&
          this.filterExternalProjects(project, includeExternal) &&
          this.dateRangeFilter(project, dateRange),
        limitTo: limit
      })

      return this.mapEntities(projects())
    })
  }

  /**
   * Selects all projects according to the searchQuery
   * This is used for global search
   * @param {string} searchQuery
   * @param {number} limit
   * @param {boolean} includeExternal
   * @returns {Observable<Project[]>}
   */
  searchProjects(searchQuery: string, limit?: number, includeExternal = false): Observable<Project[]> {
    return this.selectAll({
      limitTo: limit,
      filterBy: project =>
        (searchQuery === '*' ||
          (project.name && project.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (project.company && project.company.name.toLowerCase().includes(searchQuery.toLowerCase()))) &&
        this.filterExternalProjects(project, includeExternal),
      sortBy: (projectA, projectB) => {
        // Set scores
        projectA.score = projectA.score ? projectA.score : 0
        projectB.score = projectB.score ? projectB.score : 0

        // if the scores are different, use them to order (desc). Otherwise, order by name (asc)
        if (projectA.score !== projectB.score) {
          return projectB.score - projectA.score
        } else if (projectA.name && projectB.name) {
          return projectA.name.localeCompare(projectB.name)
        } else {
          return 0
        }
      }
    }).pipe(map(projects => this.mapEntities(projects)))
  }

  /**
   * Gets projects applying entity and quick filters
   * @param filters
   * @param {GroupByOptions} groupOption
   * @returns {Signal<Project[]>}
   */
  queryProjectByEntityAndQuickFilter(
    filters: {
      entityFilterType?: 'company' | 'user'
      entityFilterId?: string
      quickFilter?: QuickFilter
      quickStatusFilterSelected?: ProjectStatusType[]
      searchQuery?: string
      dateRange?: DateRange
      includeExternal?: boolean
    },
    groupOption?: GroupByOptions
  ): Signal<Project[]> {
    const projects =
      !filters.entityFilterType && filters.quickFilter === 'all'
        ? this.queryAllProjects({
            closedFilters: { searchQuery: filters.searchQuery },
            projectGrouping: groupOption,
            dateRange: filters.dateRange,
            includeExternal: filters.includeExternal
          })
        : this.queryByStatusType(
            filters.quickStatusFilterSelected,
            undefined,
            filters.searchQuery,
            filters.entityFilterType === 'company' ? filters.entityFilterId : null,
            filters.entityFilterType === 'user' ? filters.entityFilterId : null,
            groupOption,
            filters.quickFilter === 'my',
            filters.dateRange,
            filters.includeExternal
          )

    return computed(() => this.mapEntities(projects()))
  }

  /**
   * Gets the count of projects
   * @param {ClosedFilters} closedFilters
   * @param {string[]} filterIds
   * @param {boolean} includeExternal
   * @returns {number}
   */
  getProjectCount(closedFilters?: Partial<ClosedFilters>, filterIds?: string[], includeExternal = false): number {
    return this.getCount(
      project =>
        this.searchFilter(project, closedFilters?.searchQuery, filterIds) &&
        this.statusTypeFilter(project, closedFilters?.statusType) &&
        this.filterExternalProjects(project, includeExternal)
    )
  }

  /**
   * Selects the count of projects
   * @param {string} searchQuery
   * @param {boolean} includeExternal
   * @returns {Observable<number>}
   */
  selectProjectCount(searchQuery?: string, includeExternal = false): Observable<number> {
    return this.selectCount(
      project => this.searchFilter(project, searchQuery) && this.filterExternalProjects(project, includeExternal)
    )
  }

  /**
   * Gets the count of projects based on search and external filters
   * @param {string} searchQuery - Optional search query to filter projects
   * @param {boolean} includeExternal - Whether to include external projects
   * @returns {Signal<number>}
   */
  queryProjectCount(searchQuery?: string, includeExternal = false): Signal<number> {
    return this.queryCount(
      project => this.searchFilter(project, searchQuery) && this.filterExternalProjects(project, includeExternal)
    )
  }

  /**
   * Gets the count of internal projects
   * @returns {Signal<number>}
   */
  get queryInternalCount(): Signal<number> {
    return this.queryCount(project => !project.isExternal)
  }

  /**
   * Gets the tasksLoaded flag of a project
   * @param projectId
   */
  areTasksLoaded(projectId: string): boolean {
    const project = this.getEntity(projectId)
    return project ? project.tasksLoaded : false
  }

  /**
   * Filter used to get assigned projects.
   * @param {Project} project
   * @returns {boolean}
   */
  private assignedFilter(project: Project): boolean {
    const currentUser = this.userQuery.getCurrentUser()
    return project.members && currentUser && project.members.some(member => member.userId === currentUser.id)
  }

  /**
   * Filter used to get projects in progress and subscription
   * @param {Project} project
   * @returns {boolean}
   */
  private progressFilter(project: Project): boolean {
    return project.projectStatus?.type === 'progress'
  }

  /**
   * Filter used to get not-started projects
   * @param {Project} project
   * @returns {boolean}
   */
  private notStartedFilter(project: Project): boolean {
    return project.projectStatus?.type === 'not-started'
  }

  /**
   * Returns whether the project should be included according to the includeExternal flag
   * @param {Project} project
   * @param {boolean} includeExternal
   * @returns {boolean}
   */
  private filterExternalProjects(project: Project, includeExternal: boolean): boolean {
    return includeExternal || !project.isExternal
  }

  /**
   * Filter used for search by name and tag
   * @param project
   * @param searchQuery
   * @param filterIds
   */
  searchFilter(project: Project, searchQuery: string, filterIds?: string[]): boolean {
    if (!searchQuery) {
      return true
    }

    const isFiltered = filterIds && filterIds.includes(project.id)

    // If the project is filtered or there is no search query, the rest is not important
    if (isFiltered) {
      return false
    }

    const includesName = project.name
      ?.toLowerCase()
      .replace(/\s+/g, ' ')
      .includes(searchQuery.toLowerCase().replace(/\s+/g, ' '))
    const includesTag = project.tags?.some(t =>
      t.name.toLowerCase().replace(/\s+/g, ' ').includes(searchQuery.toLowerCase().replace(/\s+/g, ' '))
    )

    const includeCompanyName = project?.company?.name
      ?.toLowerCase()
      .replace(/\s+/g, ' ')
      .includes(searchQuery.toLowerCase().replace(/\s+/g, ' '))

    return includesName || includesTag || includeCompanyName
  }

  /**
   * Filters projects by status type or status types
   * @param {Project} project
   * @param {ProjectStatusType | ProjectStatusType[]} statusType
   * @returns {boolean}
   */
  statusTypeFilter(project: Project, statusType: ProjectStatusType | ProjectStatusType[]): boolean {
    if (!statusType || !statusType.length) {
      return true
    }

    if (Array.isArray(statusType)) {
      return statusType.some(type => project.projectStatus?.type === type)
    }

    return project.projectStatus?.type === statusType
  }

  /**
   * Filters project by company
   * @param {Project} project
   * @param {string} companyId
   * @returns {boolean}
   */
  private companyFilter(project: Project, companyId: string): boolean {
    if (!companyId) {
      return true
    }

    return project.companyId === companyId
  }

  /**
   * Filters project by member
   * @param {Project} project
   * @param {string} memberId
   * @returns {boolean}
   */
  private memberFilter(project: Project, memberId: string): boolean {
    if (!memberId) {
      return true
    }

    return project.members && project.members.some(member => member.id === memberId)
  }

  /**
   * Filters project by team
   * @param {Project} project
   * @param {string[]} teamIds
   * @returns {boolean}
   */
  private teamFilter(project: Project, teamIds: string[]): boolean {
    if (!teamIds?.length) {
      return true
    }

    return project.teams?.some(team => teamIds.some(idToFilter => idToFilter === team.id))
  }

  /**
   * Filters project by dateRange
   * @param {Project} project
   * @param {DateRange} dateRange
   * @returns {boolean}
   */
  private dateRangeFilter(project: Project, dateRange: DateRange): boolean {
    if (!dateRange) {
      return true
    }

    if (!project.startDate || !project.dueDate) {
      return false
    }

    return areDatesOverlapping(
      { start: new Date(project.startDate), end: new Date(project.dueDate) },
      { start: dateRange.startDate, end: dateRange.endDate }
    )
  }

  /**
   * Filters project by the current user being a member
   * @param {Project} project
   * @param {boolean} currentUserAssigned
   * @returns {boolean}
   * @private
   */
  private currentUserFilter(project: Project, currentUserAssigned: boolean): boolean {
    if (!currentUserAssigned) {
      return true
    }

    const currentUser = this.userQuery.getCurrentUser()

    return project.members?.some(member => member.userId === currentUser.id)
  }

  /**
   *
   * Gets the sort used for the project queries
   * Sorting: by projectStatus.typeOrder and project name, ascending
   * @param projectA
   * @param projectB
   * @param {boolean} projectStatusOrder - To apply sorting by project status type order
   * @param {GroupByOptions} projectGrouping
   * @returns {number}
   */
  private getSort(
    projectA: Project,
    projectB: Project,
    projectStatusOrder = true,
    projectGrouping?: GroupByOptions
  ): number {
    if (projectGrouping && projectGrouping !== GroupByOptions.none) {
      return getSortByGroup(projectA, projectB, projectGrouping)
    }

    if (projectA.projectStatus && projectB.projectStatus && projectStatusOrder) {
      if (projectA.projectStatus.typeOrder !== projectB.projectStatus.typeOrder) {
        return projectA.projectStatus.typeOrder - projectB.projectStatus.typeOrder
      } else {
        return new Date(projectB.createdOn).getTime() - new Date(projectA.createdOn).getTime()
      }
    }

    if (projectA.name && projectB.name) {
      return projectA.name.localeCompare(projectB.name)
    }

    return 0
  }

  /**
   * Get all projects the user is a member of
   * @param {string} assigneeId
   * @param {boolean} includeExternal
   * @returns {Project[]}
   */
  getProjectsByAssigneeId(assigneeId: string, includeExternal = false): Project[] {
    const projects = this.getAll()

    if (!projects.length) {
      return []
    }

    const filteredProjects = projects.filter(
      p => p.members.some(member => member.userId === assigneeId) && this.filterExternalProjects(p, includeExternal)
    )

    return this.mapEntities(filteredProjects)
  }

  /**
   * Sort function to sort projects by name
   * @param {Project} projectA
   * @param {Project} projectB
   * @returns {number}
   */
  private sortProjects(projectA: Project, projectB: Project): number {
    return projectA.name.localeCompare(projectB.name)
  }
}
