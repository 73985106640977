import { Injectable } from '@angular/core'
import { SignalStore } from '@awork/core/state/signal-store/signalStore'
import { EnumKeyByValue } from '@awork/_shared/types/enum-key-by-value'

/**
 * Feature flag key definitions
 */
export enum FeatureFlag {
  retainers = 'retainers',
  connect = 'connect',
  aiTextCompletionFeatures = 'ai-text-completion-features',
  datadogRum = 'datadog-rum',
  datadogReplay = 'datadog-replay',
  specialEvent = 'special-event',
  customFields = 'custom-fields',
  companyTrackedPlanned = 'tt-company-tracked-planned',
  fwkProminentSupportButton = 'fwk-prominent-support-button',
  fwkImprovedSearchExperience = 'fwk-improved-search-experience',
  wmtConnectHideable = 'wmt-connect-hideable',
  wmtShowProjectTasks = 'wmt-show-project-tasks',
  wmtPlanner = 'wmt-planner',
  wmtAutoFix = 'wmt-auto-fix',
  wmtChangelog = 'wmt-changelog',
  fwkPasswordless = 'fwk-passwordless',
  fwkDocs = 'fwk-docs'
}

/**
 * Feature flag type definition
 */
export class FeatureFlagState implements FeatureFlagDefinition {
  retainers: boolean
  aiTextCompletionFeatures: boolean
  connect: boolean
  datadogRum: number
  datadogReplay: number
  specialEvent: { startDate: Date; endDate: Date }
  customFields: boolean
  companyTrackedPlanned: boolean
  fwkProminentSupportButton: boolean
  fwkImprovedSearchExperience: boolean
  wmtConnectHideable: boolean
  wmtShowProjectTasks: boolean
  wmtPlanner: boolean
  wmtChangelog: boolean
  fwkPasswordless: boolean
  wmtAutoFix: boolean
  fwkDocs: boolean
}

export type LaunchDarklyFeatureFlags = { [value in FeatureFlag]: boolean | string | number | Object }

export type FeatureFlagKey = keyof typeof FeatureFlag
export type FeatureFlagTypeByKey<K extends FeatureFlagKey> = FeatureFlagState[K]
export type FeatureFlagDefinition = { [key in FeatureFlagKey]: FeatureFlagTypeByKey<key> }

export type FeatureFlagType<K extends FeatureFlag> = FeatureFlagState[EnumKeyByValue<typeof FeatureFlag, K>]

function createInitialState(): FeatureFlagState {
  return Object.keys(FeatureFlag).reduce((acc, key) => {
    acc[key] = false
    return acc
  }, {} as FeatureFlagState)
}

@Injectable({ providedIn: 'root' })
export class FeatureFlagStore extends SignalStore<FeatureFlagState> {
  constructor() {
    super({ name: 'featureFlag', initialState: createInitialState() })
  }

  /**
   * Updates the feature flags
   * @param {FeatureFlagState} flags
   */
  updateFlags(flags: LaunchDarklyFeatureFlags): void {
    const normalizedFlags = this.mapFeatureFlags(flags)
    this.update(normalizedFlags)
  }

  /**
   * Maps the LaunchDarkly feature flags to the FeatureFlagState.
   * If a flag is not found in the FeatureFlag enum, it is ignored.
   * @param {FeatureFlagState} flags
   * @returns {FeatureFlagState}
   */
  private mapFeatureFlags(flags: LaunchDarklyFeatureFlags): FeatureFlagState {
    return Object.keys(flags).reduce((acc, key) => {
      const enumKey = Object.keys(FeatureFlag)[Object.values(FeatureFlag).indexOf(key as FeatureFlag)]

      if (enumKey !== undefined) {
        acc[enumKey] = flags[key]
      }
      return acc
    }, {} as FeatureFlagState)
  }
}
