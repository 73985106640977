import { deepEqual, isNill } from '@awork/_shared/functions/lodash'
import { distinctUntilChanged, MonoTypeOperatorFunction } from 'rxjs'

/**
 * Coerces the given value to an array
 * @param {T | T[]} value
 * @returns {T[]}
 */
export function coerceArray<T>(value: T | T[]): T[] {
  if (isNill(value)) {
    return []
  }
  return Array.isArray(value) ? value : [value]
}

/**
 * Checks if the given value is a function
 * @param {unknown} value
 * @returns {value is Function}
 */
export function isFunction(value: unknown): value is Function {
  return typeof value === 'function'
}

/**
 * Checks if the given value is an object
 * @param {unknown} value
 * @returns {value is Object}
 */
export function isObject(value: unknown): value is Object {
  const type = typeof value
  return value !== null && type === 'object'
}

/**
 * Checks if the given array is empty
 * @param {T} arr
 * @returns {boolean}
 */
export function isEmpty<T>(arr: T): boolean {
  if (isArray(arr)) {
    return arr.length === 0
  }
  return false
}

/**
 * Checks if the given value is an array
 * @param {unknown} value
 * @returns {value is T[]}
 */
export function isArray<T>(value: unknown): value is T[] {
  return Array.isArray(value)
}

export function areArraysEqual<T>(prevCollection: T[], currentCollection: T[]): boolean {
  if (prevCollection === currentCollection) {
    return true
  }

  if (!isArray(prevCollection) || !isArray(currentCollection)) {
    return false
  }

  if (isEmpty(prevCollection) && isEmpty(currentCollection)) {
    return true
  }

  if (prevCollection.length !== currentCollection.length) {
    return false
  }

  const isOneOfItemChanged = currentCollection.some((item, i) => {
    return !deepEqual(item, prevCollection[i])
  })

  // return false means there is a change and we want to call next()
  return isOneOfItemChanged === false
}

/**
 * RXJS operator that emits only when the current array is different from the previous one
 */
export function distinctUntilArrayItemChanged<T>(): MonoTypeOperatorFunction<T[]> {
  return distinctUntilChanged((prevCollection: T[], currentCollection: T[]) => {
    return areArraysEqual(prevCollection, currentCollection)
  })
}

/**
 * Compares the given keys or functions and returns true if they are NOT changed
 * @param {(string | Function)[]} keysOrFuncs
 */
// eslint-disable-next-line no-unused-vars
export function compareKeys<T>(keysOrFuncs: (string | Function)[]) {
  return function <T>(prevState: T, currState: T) {
    return (
      keysOrFuncs.some(keyOrFunc => {
        if (isFunction(keyOrFunc)) {
          return keyOrFunc(prevState) !== keyOrFunc(currState)
        }
        return prevState[keyOrFunc] !== currState[keyOrFunc]
      }) === false
    )
  }
}

/**
 * Filters and maps the given array
 * @param {T[]} array
 * @param {(item: T) => boolean} filter
 * @param {(item: T) => T} map
 * @returns {T[]}
 */
export function filterAndMap<T>(array: T[], filter: (item: T) => boolean, map: (item: T) => T): T[] {
  return array.reduce((acc: T[], item: T) => {
    if (filter(item)) {
      acc.push(map(item))
    }
    return acc
  }, [])
}

/**
 * Adds the given item or items to the array
 * @param {T[]} array
 * @param {T | T[]} newItem
 */
export function arrayAdd<T>(array: T[], newItem: T | T[]): T[] {
  const newItems = coerceArray(newItem)
  const toArray = array || []

  return [...toArray, ...newItems]
}

export function arrayUpdate<T>(array: T[], idToUpdate: string | string[] | T, updatedValue: Partial<T>): T[]
export function arrayUpdate<T>(array: T[], predicate: (item: T) => boolean, updatedValue: Partial<T>): T[]
export function arrayUpdate<T>(
  array: T[],
  idToUpdateOrPredicate: string | string[] | T | ((item: T) => boolean),
  updatedValue: Partial<T>
): T[] {
  if (isFunction(idToUpdateOrPredicate)) {
    return array.map(item => {
      if (idToUpdateOrPredicate(item)) {
        return { ...item, ...updatedValue }
      }
      return item
    })
  }

  const ids = coerceArray(idToUpdateOrPredicate)
  return array.map(item => {
    if (ids.includes(isObject(item) ? item['id'] : item)) {
      return { ...item, ...updatedValue }
    }
    return item
  })
}

export function arrayUpsert<T>(
  array: T[],
  idToUpsert: string | string[] | T,
  updatedValue: Partial<T>,
  idProperty: keyof T = 'id' as keyof T
): T[] {
  const foundIndex = array.findIndex(item => (isObject(item) ? item[idProperty] === idToUpsert : item === idToUpsert))

  if (foundIndex !== -1) {
    const newArray = [...array]
    newArray[foundIndex] = { ...newArray[foundIndex], ...updatedValue }
    return newArray
  }

  return [...array, updatedValue as T]
}

/**
 * Removes the given item or items from the array
 * @param {T[]} array
 * @param {string | string[]} idToRemove
 */
export function arrayRemove<T>(array: T[], idToRemove: string | string[] | T): T[]
export function arrayRemove<T>(array: T[], predicate: (item: T) => boolean): T[]
export function arrayRemove<T>(array: T[], idToRemoveOrPredicate: string | string[] | T | ((item: T) => boolean)): T[] {
  if (isFunction(idToRemoveOrPredicate)) {
    return array.filter(item => !idToRemoveOrPredicate(item))
  }

  const ids = coerceArray(idToRemoveOrPredicate)
  const filterFn = (current: T) => {
    return ids.includes(isObject(current) ? current['id'] : current) === false
  }

  if (Array.isArray(array)) {
    return array.filter(filterFn)
  }

  return []
}
